<template>
   <div class="select-company-wrapper">
       <div class="flex-box-column select-company-cover">
           <div class="hd">
                <van-nav-bar left-arrow title="选择公司" :border="false" safe-area-inset-top @click-left="back"></van-nav-bar>
            </div>
            <div class="bd flex-box-column">
                <div class="search">
                    <van-search v-model="keyword" placeholder="请输入公司名称，至少三个字" @input="searchCompanyThrottle" @search="searchCompany"/>
                </div>
                <div class="scroll-view">
                    <van-list
                            v-model="loading"
                            :finished="finished"
                            finished-text="没有更多了"
                            :error.sync="error"
                            error-text="请求失败，点击重新加载"
                            :offset="100"
                            @load="onLoad"
                        >
                        <van-cell v-for="(item,index) in companyList" @click="companySelected(item)" :key="index" :title="item.companyname"></van-cell>
                    </van-list>
                </div>
            </div>
       </div>
   </div>   
</template>

<script>
/**
 * 
 * 选择渠道专用页面
 * 
 */

import api from "@/api";

import {findIndex} from "@/utils";

import backMixin from "@/mixins/back";

import throttleMixin from "@/mixins/throttle";

export default {

    mixins:[backMixin,throttleMixin],

    data(){

        return{

            keyword:'',

            finished:false,

            loading:false,

            error:false,

            companyList:[],

            selected:[],

            //当前页数

            page:1,

            //一页显示的数量

            size:20,

        }

    },

    computed:{

        selectCompany(){

            return this.$store.state.commonForm?.registerCompanyData || {};

        }

    },

    methods:{

        onLoad(){

            this.getCompanyData();

        },

        searchCompany(){

            if(this.keyword.length<=2) return;

            this.page=1;

            this.$toast.loading({

                message: '正在加载...',

                forbidClick: true,

                duration:0

            });

            //重置一下

            api.getCompanySelect({

                options:{

                    page:this.page,

                    pageSize:this.size,

                    keyword:this.keyword

                },

                success:(res)=>{
                     //首先判断是否存在勾选状态的渠道

                        this.companyList=res.data;

                        this.error=false;

                        this.finished=true;


                },

                error:(err)=>{

                    console.log(err);

                    this.error=true;

                },

                complete: ()=> {

                    this.loading=false;

                    this.$toast.clear();
                    
                }


            })



        },

        //动态自动发起搜索

        searchCompanyThrottle(){

            this.throttle({

                key:"search_company",

                time:400,

                callback:this.searchCompany
            })

        },

        //渠道数据选择

        companySelected(item){

            this.$dialog.confirm({

                title:'选择公司',

                message:`确定要选择 ${item.companyname}吗？`,

                confirmButtonColor:"#00a0e4"

            }).then(()=>{

                this.$store.commit('setCommonFormData',{

                    key:"registerCompanyData",

                    value:item?.companyname||''

                });

                this.$nextTick(()=>{

                    this.$router.back();

                })

            }).catch(()=>{});


        },


        //获取渠道数据

        getCompanyData(){

            api.getCompanySelect({

                options:{

                    page:this.page,

                    pageSize:this.size,

                },

                success:(res)=>{

                        //首先判断是否存在勾选状态的渠道

                        this.companyList = res.data;

                        this.finished=true;

                },

                error:(err)=>{

                    console.log(err);

                    this.error=true;

                },

                complete: ()=> {

                    this.loading=false;
                    
                }


            })


        },

        //首先读取当前的参数，拼凑出地址

        companySelectOver(){

            //设置渠道参数

            this.$store.commit('setRecordData',{

                key:'selectCompany',
                    
                value:this.selected,

             });
                

            this.$router.back();

        },

    }

}
</script>

<style lang="less">
.select-company-wrapper{

    height:100%;

    .select-company-cover{

        height:100%;

    }

    .bd{
        
        flex:1;

        height:0;

        overflow: hidden;
        
        .scroll-view{

            flex:1;

            height:0;

            overflow: hidden;

            overflow-y:auto;

            .selected-icon{

                padding-right:15px;

                font-size:18px;

            }

            .select-list{

                padding:5px 20px 0;

                padding-right:0;

                display: flex;

                flex-wrap: wrap;

                .item{

                    background: #f3f9ff;

                    color:#00a0e4;
                    
                    width: 30%;

                    padding: 3px 10px;

                    margin-right:8px;

                    margin-bottom:8px;

                    border-radius: 22px;

                    font-size:12px;

                    display: flex;

                    align-items: center;

                    justify-content: space-between;

                    border: 1px solid #00a0e4;

                    span:first-child{

                        max-width: 80%;

                        overflow: hidden;

                        white-space: nowrap;

                        text-overflow: ellipsis;

                    }

                    span:nth-child(2){

                        height:100%;

                        i{

                            font-size:14px;

                            position: relative;

                            top:1px;

                        }

                    }

                }
                
            }

        }

    }

}
</style>
